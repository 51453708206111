import banner from '../images/banner_img.png'
import img1 from '../images/h2_about_img.png'
import img2 from '../images/download_img.png'

import icon1 from '../images/icon1.svg'
import icon2 from '../images/icon2.svg'
import icon3 from '../images/icon3.svg'
import icon4 from '../images/icon4.svg'

import {Col, Container, Image, Modal, Nav, Navbar, Offcanvas, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useState} from "react";
import {setting} from "../helper/setting";
function Home (){
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = () => {
        if(window.innerWidth > 1200){
            setMenuOpen(false)
        }else{
            setMenuOpen(!menuOpen)
        }
    }
    const handleClose = () => setMenuOpen(false)

    return(
        <>
            <header>
                <Container>
                    <Row>
                        <Col>
                            <Navbar expand={"xl"} bg="none"  >
                                <Navbar.Brand  className={"order-1 order-xl-0"} href={"#Main"}><div  className={"text-white h2"}>{setting.title}</div></Navbar.Brand>
                                <Navbar.Toggle  onClick={toggleMenu} className={"order-0 justify-content-center order-xl-0"} aria-controls={`offcanvasNavbar-expand-false`} />
                                <Navbar.Offcanvas            restoreFocus={false}
                                                             show={menuOpen}
                                                             onHide={handleClose}

                                                             id={`offcanvasNavbar-expand-false`} aria-labelledby={`offcanvasNavbarLabel-expand-false`} placement="start">
                                    <Offcanvas.Header closeButton >
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                                <div  className={"text-dark h2"}>{setting.title}</div>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-center text-center flex-grow-1 pe-md-3 pe-0">
                                            <Nav.Link   onClick={toggleMenu} href={"#Main"}>الرئيسية</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#dalel"}>دليل الاستثمار</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#training"}>دوراتنا</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#advice"}>نصائح مهمة</Nav.Link>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                <div className={"d-md-inline-flex order-2 d-none"}>
                                    <Nav.Link style={{backgroundColor:setting.bgcolor}} className={"btn border-0 mx-1 btn-outline-primary  btn-sm fs-6  py-1 px-4   fs-6 text-white  rounded-pill "} href={setting.lp}>  سجل الان </Nav.Link>
                                </div>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
            <main id={"Main"}>
                <section className="banner-area-two text-white">
                    <div className="banner-bg-two"></div>
                    <div className="container custom-container-four">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="banner-content text-center">
                                    <h2 className="title">تعلم الان مهارات الاستثمار وإدارة المال</h2>
                                    <Image srcSet={banner} alt="img" />
                                    </div>
                            </div>
                        </div>
                    </div>
                 </section>
                <section>
                    <Container>
                        <Row>
                            <Col >
                                <div className={"box px-3 py-5 pb-4 "}>
                                    <div className={"text-center h5 fw-bold"}>تعلم الان مهارات الاستثمار وإدارة المال، من الصفر حتى الاحترافية مع دوراتنا المختلفة في عالم الاستثمار .</div>
                                    <div className={"text-center fw-bold"}>منصة الخليج السعودية تتيح الان تعلم الاستثمار بشكل دائم في أي وقت ومن أي مكان من خلال توفير العديد من:</div>
                                    <Row className={"my-4"}>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>الدورات التدريبية للمبتدئين</div>
                                        </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>دورات تدريبية احترافية</div>

                                        </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>مواد تعليمية لمختلف المتداولين</div>
                                            </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>دليل الاحتراف من الصفر حتى الاحتراف</div>

                                        </Col>
                                    </Row>

                                    <div className={"text-center"}>وغيرها الكثير تعرف معنا الان وابدأ رحلة التعلم الخاصة بك</div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <section className={"py-5 my-md-5"} id={"dalel"}>
                <Container>
                    <Row className={"align-items-center"}>
                        <Col md={7}>
                            <div className={"h5"}>قبل أن تستثمر أموالك، استثمر  معنا في تعلم الأساسيات وفهم المخاطر.</div>
                            <div >  لذلك، ابتعدنا عن التفاصيل غير الضرورية لنقدم لك دليلاً يمكن أن يساعدك في الحياة في هذا العالم الجديد والمثير.</div>
                                <div className={"fw-bold my-3"}>ماذا يشمل دليل الاستثمار للمبتدئين الخاص بمنصة الخليج:</div>
                            <ul>
                                <li>أهمية الاستثمار</li>
                                <li>كيفية البدء في الاستثمار وإدارة الأموال</li>
                                <li>الوقت المناسب للاستثمار؟</li>
                                <li>أدوات الاستثمار المتنوعة</li>
                                <li>فهم الأسواق المالية ونسبة المخاطرة</li>
                                <li>الاختلافات بين الأسهم والسندات وكيفية الاستثمار في هذه الأدوات الاستثمارية</li>
                                <li>كيفية بناء سياسة استثمارية وكيفية ادارة الاستثمارات</li>
                                <li>بعض الأساليب التي تساعد في تقييم الاستثمارات</li>
                            </ul>

                        </Col>
                        <Col md={5}>
                            <Image srcSet={img2} className={"w-100"} alt={""} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={"training"} className={"choose-bg py-5"}>
                <Container className={"py-5"}>
                    <Row className={"text-white "}>
                        <Col md={12}>
                            <div className={"text-center "}>
                                <h3 className={"h2 fw-bold"} >جاهز لتبدأ رحلة التعلم؟</h3>
                                <p className={"h5 mt-3"}>سجّل الآن باحدى الدورات التالية وابدأ رحلة العِلم إلى الاستثمار</p>
                            </div>
                            <Row className={"align-items-center mt-5 "}>
                                <Col md={3} xs={6} className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon1} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>مبادئ الاستثمار</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon2} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>كيف أبدأ بالاستثمار المالي</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon3} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>طرق تقييم الاستثمارات</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon4} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>الأصول الاستثمارية المالية</div>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={"advice"} className={"py-5 my-md-3"}>
                <Container>
                    <Row className={"align-items-center"}>
                        <Col md={7}>
                            <div className={"h5 fw-bold my-md-3"}>هناك 5 نصائح مهمة نقدمها لك يجب تذكرها قبل أن تبدأ بالاستثمار</div>

                            <ul>
                                <li>ابدا بتعلم الاستثمار وكيفية تجنب المخاطر المتعلقة بالخسارة</li>
                                <li>حدد المبلغ الذي يمكنك توفيره واستثماره.</li>
                                <li>فكر في البدء على نطاق صغير  أولا لترى كيف ستسير الأمور.</li>
                                <li>كن مستعدًا لعدم الاعتماد على صرف استثمارك لمدة 5 سنوات على الأقل.</li>
                                <li>ضع في اعتبارك طلب المساعدة والاستشارة لتحديد الخيار المناسب لك.</li>
                            </ul>

                        </Col>
                        <Col md={5}>
                            <Image srcSet={img1} className={"w-100"} alt={""} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className={"bg-black py-5"}>
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Nav className="justify-content-center text-center ">
                                <Nav.Link  className={"text-white d-none d-md-block"}  href={"#Main"}>الرئيسية</Nav.Link>
                                <Nav.Link  className={"text-white"} href={"#dalel"}>دليل الاستثمار</Nav.Link>
                                <Nav.Link  className={"text-white"}  href={"#training"}>دوراتنا</Nav.Link>
                                <Nav.Link  className={"text-white"} href={"#advice"}>نصائح مهمة</Nav.Link>
                            </Nav>
                        </Col>
                        <Col md={12} className="text-justify  text-white">
                            <div className="fw-bold my-2 text-center">
                                <Link to="./cookie-policy" style={{color:setting.bgcolor}} className="text-decoration-none fw-light">شروط الاستخدام</Link><span> و </span>
                                <Link to="./cookie-policy" style={{color:setting.bgcolor}} className="text-decoration-none fw-light" >سياسة الخصوصية</Link>
                            </div>
                            <div className="text-center">حقوق النشر © 2021 جميع الحقوق محفوظة</div>
                        </Col>
                    </div>
                </div>
            </footer>
            {
                window.location.origin.includes("mrtrsa.com") &&
                <Modal show={true} centered backdrop={true} >
                    <Modal.Body dir={"ltr"} className={"text-center"}>
                        <h1>Important Notice!</h1>
                        <p>We've changed our domain to a new and modern one.
                            <br />
                            Please update your bookmarks!</p>
                    </Modal.Body>
                </Modal>
            }

        </>
    )
}
export default Home
